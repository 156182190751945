<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text :color="color" :loading="loading">
        {{name}}
      </v-btn>
    </template>
    <v-sheet class="ma-4" width="240" style="margin-left:-100px">
      <v-row no-gutters>
        <v-col cols="12" class="body-2 mb-4">
          {{text}}
        </v-col>
        <v-col>
          <v-btn text small>Отменить</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="$emit('accept')" text small :color="color">Подтвердить</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-menu>
</template>

<script>

export default {
  props:[
    'name',
    'color',
    'text',
    'loading',
  ],
  data:()=>({
  })
}
</script>