<template>
  <layout-view navTitle="Категории" :nav-loading="apiNomenCats.loading">
    <template v-slot:navBarActions>
      <v-btn small depressed @click="nomenCatCreateUpdate={id:null,name:null}" class="mt-n1">Создать</v-btn>
    </template>
    <template v-slot:nav>
      <v-list dense>
        <v-list-item-group v-model="nomenCatsIndex" mandatory>
          <v-list-item v-for="(item, i) in nomenCats" :key="i">
            <v-list-item-content>
              <v-list-item-title>{{item.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-if="nomenCats.length>0 && typeof nomenCatsIndex!=='undefined'" v-slot:content="{height}">
      <toolbar-view title="Номенклатура">
        <template v-slot:barActions>
          <v-btn small depressed @click="nomenCatCreateUpdate=nomenCats[nomenCatsIndex]" class="mr-2">Изменить категорию</v-btn>
          <btn-small-accept name="Удалить категорию" text="Будут отвязаны все услуги из номенклатуры и удалена вся номенклатура из категории. Удалить категорию?" @accept="nomenCatDelete(nomenCats[nomenCatsIndex].id)"/>
          <dot/>
          <v-btn small depressed @click="nomenCreateUpdate={id:null,name:null,code:null,nomenCat:nomenCats[nomenCatsIndex]}">Создать номенклатуру</v-btn>
        </template>
      </toolbar-view>
      <v-data-table
          :headers="[
            { text: 'ID', sortable: false},
            { text: 'Название', sortable: false},
            { text: 'Код', sortable: false},
          ]"
          :items="nomens"
          :server-items-length="nomensCount"
          :loading="apiNomens.loading"
          :options.sync="nomensOptions"
          :footer-props="{'items-per-page-options': [100,500,1000,10000]}"
          dense
          :height="height-48-48"
          fixed-header
      >
        <template v-slot:item="{ item }">
          <tr @click="nomenCreateUpdate=item">
            <td>{{item.id}}</td>
            <td class="text-wrap">{{item.name}}</td>
            <td>{{item.code}}</td>
          </tr>
        </template>
      </v-data-table>
    </template>
    <template v-slot:footer>
      <dialog-editor
          :name="nomenCatCreateUpdate===null || nomenCatCreateUpdate.id===null ? 'Создать категорию номенклатуры' : 'Редактировать категорию номенклатуры'"
          :item.sync="nomenCatCreateUpdate"
          @submit="nomenCatCreateUpdateAction"
      >
        <template v-slot:default="{localItem}">
          <text-field name="Название" :value.sync="localItem.name"/>
        </template>
      </dialog-editor>
      <dialog-editor
          :name="nomenCreateUpdate===null || nomenCreateUpdate.id===null ? 'Создать номенклатуру' : 'Редактировать номенклатуру'"
          :item.sync="nomenCreateUpdate"
          @submit="nomenCreateUpdateAction"
      >
        <template v-slot:default="{localItem}">
          <text-field name="Название" :value.sync="localItem.name" required/>
          <text-field name="Цена" :value.sync="localItem.price" after-icon="mdi-currency-rub" format="^[0-9]+\.?[0-9]?[0-9]?$" formatExample="1000 или 1000.10" required/>
          <text-field name="Код" :value.sync="localItem.code"/>
          <autocomplete-single name="Категория" :items="nomenCats" :select.sync="localItem.nomenCat" required />
        </template>
        <template v-if="nomenCreateUpdate!==null && nomenCreateUpdate.id!==null" v-slot:footerActions="{}">
          <btn-text-accept name="Удалить" text="Удалить?" color="error" @accept="nomenDelete(nomenCreateUpdate.id)"/>
        </template>
      </dialog-editor>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView.vue";
import GraphqlApi from "@/plugins/graphqlApi";
import DialogEditor from "@/componentsV2/base/DialogEditor.vue";
import BtnTextAccept from "@/componentsV2/base/BtnTextAccept.vue";
import TextField from "@/componentsV2/base/TextField.vue";
import axios from "axios";
import Api from "@/Api";
import ToolbarView from "@/components/ToolbarView.vue";
import BtnSmallAccept from "@/componentsV2/base/BtnSmallAccept.vue";
import Dot from "@/componentsV2/base/Dot.vue";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";

export default {
  components: {
    AutocompleteSingle,
    Dot,
    BtnSmallAccept,
    ToolbarView,
    TextField,
    BtnTextAccept,
    DialogEditor, LayoutView},
  data: () => ({
    apiNomenCats: new GraphqlApi(),
    nomenCats: [],
    nomenCatsIndex:0,
    nomenCatCreateUpdate:{},

    apiNomens: new GraphqlApi(),
    nomens: [],
    nomensCount: 0,
    nomensOptions: {},
    nomenCreateUpdate:{},
  }),
  mounted() {
    this.nomenCatsLoad();
  },
  watch:{
    nomenCatsIndex(){
      if(this.nomenCats.length>0)
        this.nomensLoad(this.nomenCats[this.nomenCatsIndex].id);
    },
    nomensOptions() {
      this.nomensLoad(this.nomenCats[this.nomenCatsIndex].id)
    },
  },
  methods:{
    nomenCatsLoad(){
      this.apiNomenCats.request(
          "/api/graphqlv2/",
          `
        query Query{
          nomenCats{
            count
            items {
              id
              name
            }
          }
        }
      `,
          { },
          (d)=>{
            this.nomenCats = d.nomenCats.items;
            if(this.nomenCats.length>0)
              this.nomensLoad(this.nomenCats[0].id);
          },
          ()=>{
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
          }
      );
    },
    nomenCatCreateUpdateAction(data){
      if(data.id===null){
        axios.post(Api.host+'/nomenCat/', data)
          .then(()=>{
            this.$store.commit('alertSuccess', 'Успешно создано')
            this.nomenCatCreateUpdate = null;
            this.nomenCatsLoad();
          })
          .catch(() => this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку'));
      } else {
        axios.put(Api.host+'/nomenCat/'+data.id+'/', data)
            .then(()=>{
              this.$store.commit('alertSuccess', 'Успешно изменено')
              this.nomenCatCreateUpdate = null;
              this.nomenCatsLoad();
            })
            .catch(() => this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку'));
      }
    },
    nomenCatDelete(id){
      axios.delete(Api.host+'/nomenCat/'+id+'/')
          .then(()=>{
            this.$store.commit('alertSuccess', 'Успешно удалено')
            this.nomenCatsIndex = 0;
            this.nomenCatsLoad();
          })
          .catch(() => this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку'));
    },
    nomensLoad(nomenCatId){
      this.$tools.throttle(()=>{
        this.apiNomens.request(
            "/api/graphqlv2/",
            `
            query Query($page:Int,$onPage:Int,$nomenCatId:String){
              nomens(
                page: $page
                onPage: $onPage
                whereQL:"nomenCat.id=?1"
                whereQLParams:$nomenCatId
              ){
                count
                items {
                  id
                  name
                  price
                  code
                  nomenCat{
                    id
                  }
                }
              }
            }
          `,
            { nomenCatId: nomenCatId+"", page: this.nomensOptions.page, onPage: this.nomensOptions.itemsPerPage},
            (d)=>{
              this.nomens = d.nomens.items;
              this.nomensCount = d.nomens.count;
            },
            ()=>{
              this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
            }
        );
      },100)
    },
    nomenCreateUpdateAction(data){
      if(data.id===null){
        axios.post(Api.host+'/nomen/', data)
            .then(()=>{
              this.$store.commit('alertSuccess', 'Успешно создано')
              this.nomenCreateUpdate = null;
              this.nomensLoad(data.nomenCat.id);
            })
            .catch(() => this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку'));
      } else {
        axios.put(Api.host+'/nomen/'+data.id+'/', data)
            .then(()=>{
              this.$store.commit('alertSuccess', 'Успешно изменено')
              this.nomenCreateUpdate = null;
              this.nomensLoad(data.nomenCat.id);
            })
            .catch(() => this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку'));
      }
    },
    nomenDelete(id){
      axios.delete(Api.host+'/nomen/'+id+'/')
          .then(()=>{
            this.nomensLoad(this.nomenCreateUpdate.nomenCat.id);
            this.$store.commit('alertSuccess', 'Успешно удалено')
            this.nomenCreateUpdate = null;
          })
          .catch(() => this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку'));
    },
  }
}
</script>