<template>
  <div>
    <v-combobox
        v-model="selectedItem"
        :items="items"
        :search-input.sync="searchText"
        :label="'Код'+(!dataState.isLoading && $tools.isEmpty(selectedItem) && !$tools.isEmpty(this.code) ? ' ('+this.code+' не найден)': '')"
        outlined
        dense
        hide-details="auto"
    >
      <template v-slot:prepend-inner>
        <v-sheet width="24" height="24" class="d-flex align-center justify-center">
          <v-progress-circular v-if="dataState.isLoading" color="primary" size="16" width="2" indeterminate/>
          <v-icon v-else>mdi-magnify</v-icon>
        </v-sheet>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content v-if="$tools.isEmpty(searchText)">
            <v-list-item-title>Введите текст для поиска</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title v-if="dataState.isLoading">Идет поиск...</v-list-item-title>
            <v-list-item-title v-if="items.length===0 && dataState.isSuccess">Не найдено по запросу</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item="{item}">
        <div class="my-n1 ml-n4">
          <service-code-view-detail :item="item"/>
        </div>
      </template>
      <template v-slot:selection="{item}">
        <div v-if="item.value!=null" class="py-2 full-width">
          <service-code-view :code="item.value.code" content-class="mr-2"/>
          <div class="body-2 mt-1 font-weight-medium">{{item.value.name}}</div>
          <v-menu v-if="!$tools.isEmpty(item.value.additional_info)" open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on" class="mt-n1 position-absolute">
                <v-icon small>mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <v-card max-width="500">
              <div class="py-2 px-4 body-2" v-html="item.value.additional_info"></div>
            </v-card>
          </v-menu>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import State from "@/plugins/state";
import axios from "axios";
import ServiceCodeViewDetail from "@/views/Service/ServiceCodeViewDetail";
import ServiceCodeView from "@/views/Service/ServiceCodeView";

export default {
  components: {ServiceCodeView, ServiceCodeViewDetail},
  props:['code'],
  data: () => ({
    dataState: new State(),
    searchText:'',
    searchTextLast:'',
    items:[],
    selectedItem:null,
  }),
  watch:{
    searchText() {
      this.search();
    },
    selectedItem() {
      if(this.$tools.isEmpty(this.selectedItem))
        this.$emit('success', null);
      else
        this.$emit('success', this.selectedItem.value.code);
    },
  },
  mounted(){
    this.searchDefault();
  },
  methods:{
    searchDefault(){
      if (this.code!==null) {
        this.dataState.stateLoading();
        this.searchTextLast = this.code;
        axios.get('/api/service/code/search/', {
          params: {
            q: this.code
          }
        }).then((v) => {
          this.dataState.stateSuccess();
          if(v.data.length>0){
            let b = v.data[0];

            b['additional_info'] = b['additional_info'].split("\n").join("<br/>");
            this.selectedItem = {
              text:
                  b['id'] + '|' +
                  b['code'] + '|' +
                  b['name'] + '|' +
                  b['additional_info'] + '|' +
                  b['dir'],
              value: b
            };
          }
        }).catch((e) => {
          this.dataState.stateError();
          console.log(e);
        })
      }
    },

    search(){
      if (this.searchText!=null && this.searchText.length>1 && this.searchText!==this.searchTextLast) {
        this.dataState.stateLoading();
        this.searchTextLast = this.searchText;
        axios.get('/api/service/code/search/', {
          params: {
            q: this.searchText
          }
        }).then((v) => {
          this.dataState.stateSuccess();
          this.items = [];
          v.data.forEach((v) => {
            v['additional_info'] = v['additional_info'].split("\n").join("<br/>")
            this.items.push({
              text:
                  v['id'] + '|' +
                  v['code'] + '|' +
                  v['name'] + '|' +
                  v['additional_info'] + '|' +
                  v['dir'],
              value: v
            });
          });
        }).catch((e) => {
          this.dataState.stateError();
          console.log(e);
        })
      }
    },
  }
}
</script>