<template>
  <v-autocomplete
      v-model="selected"
      :items="items"
      :search-input.sync="text"
      :label="typeof title!=='undefined' ? title : (type==='client' ? 'Клиенты' : type==='spec' ? 'Специалисты' : type==='admin' ? 'Администраторы' : 'Пользователи')"
      outlined
      dense
      hide-details="auto"
      multiple
      class="mb-4"
      item-text="fullName"
      item-value="id"
      @click="load()"
  >
    <template v-slot:append>
      <v-sheet v-if="state.isLoading" width="24" height="24" class="d-flex align-center justify-center">
        <v-progress-circular color="primary" size="16" width="2" indeterminate/>
      </v-sheet>
    </template>
    <template v-slot:no-data>
      <div v-if="state.isLoading"></div>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title>{{state.isLoading ? '' : 'Не найдено'}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{item}">
      <v-list-item-avatar>
        <v-icon v-if="$tools.isEmpty(item.imageUrl)" size="40">mdi-account-circle</v-icon>
        <img v-else :src="item.imageUrl" alt="">
      </v-list-item-avatar>
      <v-list-item-content style="width:360px">
        <v-list-item-title>{{item.fullName}} <span v-if="(typeof birthDay !== 'undefined')">({{ $tools.dateTimeFormat(item.userProfile.birth, 'DD.MM.YYYY') }})</span></v-list-item-title>
        <v-list-item-subtitle>
          <div v-for="userSpec in item.userSpecs" :key="item.id+'_'+userSpec.id" class="text-wrap">
            {{userSpec.spec.name}}
            <v-icon small>mdi-chevron-left</v-icon>
            {{userSpec.comDiv.comBranch.name}}
            <v-icon v-if="!$tools.isEmpty(userSpec.commentAdmin)" class="ml-2 mr-1" small>mdi-comment-text-outline</v-icon>
            {{userSpec.commentAdmin}}
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{item}">
      <v-chip class="full-width mt-2 px-0" small style="width:95%">
        <v-row no-gutters class="align-center">
          <v-col cols="auto">
            <v-avatar class="mr-2">
              <v-icon v-if="$tools.isEmpty(item.imageUrl)" size="28" class="ma-0 white">mdi-account-circle</v-icon>
              <img v-else :src="item.imageUrl" alt="">
            </v-avatar>
          </v-col>
          <v-col class="text-overflow">{{item.name}}</v-col>
          <v-col cols="auto"><v-icon small @click.stop="selected=selected.filter((v)=>v!==item.id)">mdi-close-circle</v-icon></v-col>
        </v-row>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>

import State from "@/plugins/state";
import Api from "@/Api";

export default {
  props:['title', 'type', 'multiple', 'birthDay', 'cookiesName'],
  data:()=>({
    selected:null,
    items:[],
    text:null,
    state: new State(),
    cookieValue: null,
    firstLoad: false
  }),
  watch:{
    text() {
      this.$tools.throttle(()=>this.load());
    },
    selected(){
      if(this.multiple===false && this.selected.length>1) {
          setTimeout(()=>{
            this.selected = [this.selected[1]];
          },100);
      }  

      let arr =  [];
      this.selected.forEach((v)=>{
        this.items.forEach((v1)=>{
          if(v1.id===v) {
          arr.push(v1);
          }
        });
      });

      if(this.multiple===false) {
        this.$emit('selected', arr.length>0 ? arr[0] : []);
      } 
      else {
        this.$emit('selected', arr);
      }

      this.text = null;

      //cookies
      if (typeof this.cookiesName !== 'undefined')
      this.saveUsersSelectedIds(arr);
      
    }
  },
  mounted(){
    //cookies
    if (typeof this.cookiesName !== 'undefined')
    this.cookieValue = JSON.parse(this.$cookie.get(this.cookiesName));

    if(this.cookieValue&&this.cookieValue.length>0)
    this.load();  
  },
  methods:{
    load(){
      if (this.items.length === 0||this.text) {
        let query = `
          query Query(
            $search: String
            $type: String
          ){
            User(
              sortBy:"lastName"
              sortDesc:false
              type:$type
              search:$search
            ){
              id
              name
              fullName
              phone
              imageUrl
              userSpecs{
                id
                commentAdmin
                spec{
                  name
                }
                comDiv{
                  comBranch{
                    name
                  }
                }
              }
              userProfile {
                birth
              }
            }
          }
        `;
        let queryVars = {
          search:this.text,
          type:this.type
        };

        this.state.stateLoading();
        Api.graphql(query, queryVars, (data)=>{
          this.state.stateSuccess()

          Array.prototype.push.apply(this.items, data.User);

          this.items = [...new Set(this.items)];
          
          //cookies
          if(this.cookieValue&&this.cookieValue.length>0&&this.firstLoad === false) {
            this.selected = this.cookieValue 
          }

          this.firstLoad = true;
        }, (e)=>{
          this.state.stateError(e)
        });
      }  
    },
    saveUsersSelectedIds(arr) {
      if(arr.length>0) {    
        let ids =  [];
        arr.forEach((user)=>{
          ids.push(user.id);
        });
        this.$cookie.set(this.cookiesName, JSON.stringify([...new Set(ids)]), 30);
      } else {
        this.$cookie.delete(this.cookiesName);
      }
    },


  }
}
</script>

