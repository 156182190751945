<template>
  <sheet-group class="mb-4">
    <div>Анализы в лаборатории:</div>
    <service-options :optionsJson.sync="optionsJson"/>
    <progress-page v-if="labsState.isLoading"/>
    <v-expansion-panels v-if="labsState.isSuccess" accordion multiple>
      <v-expansion-panel v-for="(v,k) in labs.filter(v=>v.id!=='medicallab')" :key="k">
        <v-expansion-panel-header class="px-2">{{v.name}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <alert v-if="items.filter(v1=>v1.labId===v.id).length===0">Не найдено</alert>
          <div class="mx-n4 mt-1">
            <v-row v-for="(v1,k) in items.filter(v2=>v2.labId===v.id)" :key="k" class="rowSmall">
              <v-col>
                <div>{{v1.code}}</div>
                <div>{{v1.name}}</div>
              </v-col>
              <v-col cols="auto">
                <btn-icon-accept @accept="items=items.filter(v2=>v2.id!==v1.id);update()" icon="mdi-close" text="Удалить?"/>
              </v-col>
              <v-col v-if="k<items.filter(v2=>v2.labId===v.id).length-1" cols="12">
                <v-divider/>
              </v-col>
            </v-row>
            <v-row class="rowSmall" align="center">
              <v-col>
                <service-lab-item-autocomplete-single class="mb-0" :select.sync="itemSelect" :lab-id="v.id"/>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="items.push({labId:v.id,code:itemSelect.code,name:itemSelect.name});update()" block depressed>Добавить</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </sheet-group>
</template>

<script>
import SheetGroup from "@/componentsV2/base/SheetGroup.vue";
import axios from "axios";
import State from "@/plugins/state";
import ProgressPage from "@/components/ProgressPage.vue";
import {Entity_Service, Entity_ServiceLabItem} from "../../../EntityStoreCacheService";
import BtnIconAccept from "@/componentsV2/base/BtnIconAccept.vue";
import Alert from "@/componentsV2/base/Alert.vue";
import ServiceLabItemAutocompleteSingle from "@/componentsV2/custom/ServiceLabItemAutocompleteSingle.vue";
import ServiceOptions from "@/views/Service/ServiceOptions.vue";

export default {
  components: {ServiceOptions, ServiceLabItemAutocompleteSingle, Alert, BtnIconAccept, ProgressPage, SheetGroup},
  props:['optionsJson','serviceId'],
  data:()=>({
    labsState:new State(),
    labs:[],
    loadState:new State(),
    items:[],
    itemSelect:null,
  }),
  mounted() {
    this.labsState.stateLoading();
    axios.get('/napi/lab/')
        .then(r =>{
          this.labs = r.data;
          this.labsState.stateSuccess();
        })
        .catch(() =>{
          this.labsState.stateError();
        });
    this.itemsLoad();
  },
  methods:{
    itemsLoad(){
      new Entity_ServiceLabItem()
          .selects(Entity_ServiceLabItem.id)
          .selects(Entity_ServiceLabItem.labId)
          .selects(Entity_ServiceLabItem.code)
          .selects(Entity_ServiceLabItem.name)
          .filterAnd([Entity_ServiceLabItem.entity,Entity_ServiceLabItem.service, Entity_Service.id].join('.')+'=?', this.serviceId)
          .order(Entity_ServiceLabItem.name, true)
          .onPage(100)
          .addStateCallback((v)=> this.loadState.setRequestBuilderState(v))
          .addStateCallback((r)=>{
            if(r.success){
              this.items = r.items;
            }
          })
          .request();
    },
    update(){
      axios.put('/api/service/'+this.serviceId+'/labItem/', this.items)
        .then(() =>{
          this.itemsLoad();
        })
        .catch(() =>{
        });
    }
  }
}
</script>