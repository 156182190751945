<template>
  <layout-view navTitle="Категории" :nav-loading="directionTreeState.isLoading">
    <template v-slot:nav>
      <v-treeview
          :items="directionTree"
          :active.sync="directionTreeSelectedArr"
          item-key="sid"
          expand-icon="mdi-chevron-down"
          activatable
          hoverable
          dense
          rounded
          class="tree px-4"
      >
        <template v-slot:prepend="{ item }">
          <v-icon v-if="item.type.indexOf('Create')!==-1" small class="pl-1">mdi-plus</v-icon>
          <v-icon v-if="item.type==='directionCat'" small class="pl-1">mdi-folder-outline</v-icon>
          <v-icon v-if="item.type==='direction'" small class="pl-1">mdi-tag-outline</v-icon>
        </template>
        <template v-slot:label="{ item }">
          <span class="body-2">{{item.name}}</span>
        </template>
      </v-treeview>
    </template>
    <template v-slot:content="{height}">
      <div v-if="directionTreeSelected!==null">
        <div v-if="directionTreeSelected.type==='directionCatCreate'">
          <toolbar-view title="Создать категорию"/>
          <div class="pa-4">
            <v-row class="rowSmall">
              <v-col cols="4">
                <v-text-field v-model="directionCatCreate.name" key="directionCatName" label="Название" class="mb-4" outlined dense hide-details="auto"/>
                <v-btn depressed color="primary" small @click="directionCatCreateAction(directionCatCreate)">Создать</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="directionTreeSelected.type==='directionCat'">
          <toolbar-view title="Изменить категорию"/>
          <div class="pa-4">
            <v-row class="rowSmall">
              <v-col cols="4">
                <v-text-field v-model="directionTreeSelected.data.name" key="directionCatName" label="Название" class="mb-4" outlined dense hide-details="auto"/>
                <btn-small-accept :disabled="!directionTreeSelected.data.isCanDelete" btnClass="mr-2" color="error" name="Удалить" text="Все вложенные направления будут удалены, связанные данные отвязаны. Удалить категорию?" @accept="directionCatDeleteAction(directionTreeSelected.data)"/>
                <v-btn depressed color="primary" small @click="directionCatUpdateAction(directionTreeSelected.data)">Изменить</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="directionTreeSelected.type==='directionCreate'">
          <toolbar-view title="Создать направление"/>
          <div class="pa-4">
            <v-row class="rowSmall">
              <v-col cols="4">
                <v-text-field v-model="directionCreate.name" key="directionName" label="Название" class="mb-4" outlined dense hide-details="auto"/>
                <v-btn depressed color="primary" small @click="directionCreateAction(directionTreeSelected.data, directionCreate)">Создать</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="directionTreeSelected.type==='direction'">
          <v-tabs v-model="tabIndex" background-color="transparent">
            <v-tab>Изменить направление</v-tab>
            <v-tab>Категории услуг/товаров</v-tab>
            <v-tab>Услуги/товары</v-tab>
            <v-tab>Планы лечения</v-tab>
          </v-tabs>
          <div v-if="tabIndex===0" class="pa-4">
            <v-row class="rowSmall">
              <v-col cols="4">
                <v-text-field v-model="directionTreeSelected.data.name" key="directionName" label="Название" class="mb-4" outlined dense hide-details="auto"/>
                <autocomplete-single name="Категория" :items="directionsCats" :select.sync="directionTreeSelected.data.directionCat"/>
                <btn-small-accept btnClass="mr-2" color="error" name="Удалить" text="Все связанные данные будут отвязаны. Удалить напраление?" @accept="directionDeleteAction(directionTreeSelected.data)"/>
                <v-btn depressed color="primary" small @click="directionUpdateAction(directionTreeSelected.data)">Изменить</v-btn>
              </v-col>
            </v-row>
          </div>
          <n-table-view
              v-if="tabIndex===1"
              key="serviceCats"
              ref="serviceCats"
              name="Список"
              :genRequestBuilder="serviceCatsRequestBuilder"
              :height="height-48"
              :headers="[
                { text: 'ID', value: 'id', class:'text-no-wrap'},
                { text: 'Название', value: 'name', class:'text-no-wrap'},
              ]"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-no-wrap">{{item.id}}</td>
                <td class="text-wrap">{{item.name}}</td>
              </tr>
            </template>
          </n-table-view>
          <n-table-view
              v-if="tabIndex===2"
              key="services"
              ref="services"
              name="Список"
              tableClass="tableMultipleSelect"
              :selectAll.sync="servicesSelectAll"
              :selectIndeterminate="servicesSelectIndeterminate"
              :genRequestBuilder="servicesRequestBuilder"
              :height="height-48"
              :headers="[
                { text: 'ID', value: 'id', class:'text-no-wrap'},
                { text: 'Название', value: 'name', class:'text-no-wrap'},
                { text: 'Код', value: 'code', class:'text-no-wrap'},
              ]"
          >
            <template v-slot:barActions>
              <v-btn depressed small @click="serviceAttachOpen=true">Привязать</v-btn>
              <v-btn v-if="servicesSelects.length>0" depressed small class="ml-2" @click="serviceDettachAction(directionTreeSelected.data)">Отвязать</v-btn>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-checkbox @click.stop="$tools.selectToggle(servicesSelects, item)" :input-value="$tools.isSelect(servicesSelects, item)" class="ma-0 pa-0" dense hide-details />
                </td>
                <td class="text-no-wrap">{{item.id}}</td>
                <td class="text-wrap">{{item.name}}</td>
                <td class="text-wrap">{{item.code}}</td>
              </tr>
            </template>
          </n-table-view>
          <n-table-view
              v-if="tabIndex===3"
              key="medPlans"
              ref="medPlans"
              name="Список"
              :genRequestBuilder="medPlansRequestBuilder"
              :height="height-48"
              :headers="[
            { text: 'ID', value: 'id', class:'text-no-wrap'},
            { text: 'Название', value: 'name', class:'text-no-wrap'},
          ]"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-no-wrap">{{item.id}}</td>
                <td class="text-wrap">{{item.name}}</td>
              </tr>
            </template>
          </n-table-view>
          <visit-service-add :open.sync="serviceAttachOpen" hideFree @complete="(v)=>serviceAttachAction(directionTreeSelected.data, v)" />
        </div>
      </div>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView.vue";
import {
  Entity_Direction,
  Entity_DirectionCat, Entity_MedPlan,
  Entity_Service,
  Entity_ServiceCat
} from "../../../EntityStoreCacheService";
import State from "@/plugins/state";
import NTableView from "@/componentsV2/base/NTableView.vue";
import ToolbarView from "@/components/ToolbarView.vue";
import BtnSmallAccept from "@/componentsV2/base/BtnSmallAccept.vue";
import axios from "axios";
import Api from "@/Api";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import VisitServiceAdd from "@/views/Visit/VisitServiceAdd.vue";

export default {
  components: {
    VisitServiceAdd,
    AutocompleteSingle, BtnSmallAccept, ToolbarView, NTableView, LayoutView},
  data: () => ({
    directionTreeState: new State(),
    directionTree: [],
    directionTreeSelectedArr: [],
    tabIndex:0,
    directionsCats:[],
    serviceCats:[],
    services:[],
    servicesSelects:[],
    medPlans:[],
    directionCatCreate:{name:null},
    directionCreate:{name:null},

    serviceAttachOpen:false,
  }),
  mounted() {
    this.directionCatsLoad();
  },
  computed:{
    directionTreeSelected:{
      get(){
        let founder = (v)=>{
          if(v.sid===this.directionTreeSelectedArr[0])
            return v;
          let found = null;
          v.children.forEach(v1=>{
            if(found===null)
              found = founder(v1);
          })
          return found;
        };
        let found = null;
        this.directionTree.forEach(v=>{
          if(found===null)
            found = founder(v);
        });
        if(found!==null && found.type==='direction'){
          this.$tools.throttle(()=>{
            if(this.tabIndex===1) this.$refs.serviceCats.load(true);
            if(this.tabIndex===2) this.$refs.services.load(true);
            if(this.tabIndex===3) this.$refs.medPlans.load(true);
            this.servicesSelects = [];
          }, 100);
        }
        return found;
      },
      set(){}
    },
    servicesSelectAll:{
      get(){
        return this.$tools.isSelectAll(this.services, this.servicesSelects)
      },
      set(){
        this.$tools.selectAllToggle(this.services, this.servicesSelects)
      }
    },
    servicesSelectIndeterminate:{
      get(){
        return this.$tools.isSelectIntermidiate(this.services, this.servicesSelects)
      },
    },
  },
  methods:{
    directionCatsLoad(){
      new Entity_DirectionCat()
          .selects(Entity_DirectionCat.id)
          .selects(Entity_DirectionCat.name)
          .selects(Entity_DirectionCat.isCanDelete)
          .selects([Entity_DirectionCat.directions,Entity_Direction.id].join('.'))
          .selects([Entity_DirectionCat.directions,Entity_Direction.name].join('.'))
          .order(Entity_DirectionCat.name, true)
          .page(1)
          .onPage(100)
          .addStateCallback((v)=> this.directionTreeState.setRequestBuilderState(v))
          .addStateCallback((v)=>{
            if(v.error)
              this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
            if(v.success) {
              this.directionsCats = v.items;
              this.directionsCats.forEach(v1=>{
                v1.directions.forEach(v2=>{
                  v2.directionCat = {
                    id:v1.id,
                    name:v1.name,
                  }
                })
              })

              let index = 0;
              this.directionTree = [{
                sid: index++,
                name:'Создать категорию',
                type: 'directionCatCreate',
                data:null,
                children:[],
              }];

              v.items.forEach(directionCat=>{

                this.directionTree.push({
                  sid: index++,
                  name:directionCat.name,
                  type: 'directionCat',
                  data:directionCat,
                  children:(()=>{
                    let directions = [{
                      sid: index++,
                      name:'Создать направление',
                      type: 'directionCreate',
                      data:directionCat,
                      children:[],
                    }];
                    directionCat.directions.forEach(direction=>{
                      directions.push({
                        sid: index++,
                        name:direction.name,
                        type: 'direction',
                        data:direction,
                        children:[]
                      });
                    })
                    return directions;
                  })(),
                });
              });

              this.directionTreeSelectedArr = [this.directionTree[0].sid];
            }
          })
          .request()
    },
    serviceCatsRequestBuilder(page, onPage, sortFields){
      return new Entity_ServiceCat()
          .selects(Entity_ServiceCat.id)
          .selects(Entity_ServiceCat.name)
          .page(page)
          .onPage(onPage)
          .orderFromTable(sortFields)
          .filterAnd([Entity_ServiceCat.entity, Entity_ServiceCat.directions,Entity_Direction.id].join('.')+'=?', this.directionTreeSelected.data.id)
          .addStateCallback((v)=>{
            if(v.error)
              this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
            if(v.success)
              this.serviceCats = v.items;
          })
    },
    servicesRequestBuilder(page, onPage, sortFields){
      return new Entity_Service()
          .selects(Entity_Service.id)
          .selects(Entity_Service.name)
          .selects(Entity_Service.code)
          .page(page)
          .onPage(onPage)
          .orderFromTable(sortFields)
          .filterAnd([Entity_Service.entity, Entity_Service.directions,Entity_Direction.id].join('.')+'=?', this.directionTreeSelected.data.id)
          .filterAnd([Entity_Service.entity, Entity_Service.status].join('.')+'=?', 'active')
          .addStateCallback((v)=>{
            if(v.error)
              this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
            if(v.success)
              this.services = v.items;
          })
    },
    medPlansRequestBuilder(page, onPage, sortFields){
      return new Entity_MedPlan()
          .selects(Entity_MedPlan.id)
          .selects(Entity_MedPlan.name)
          .page(page)
          .onPage(onPage)
          .orderFromTable(sortFields)
          .filterAnd([Entity_MedPlan.entity, Entity_MedPlan.directions,Entity_Direction.id].join('.')+'=?', this.directionTreeSelected.data.id)
          .addStateCallback((v)=>{
            if(v.error)
              this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
            if(v.success)
              this.medPlans = v.items;
          })
    },

    directionCatCreateAction(v){
      axios.post(Api.host+'/directionCat/', v)
          .then(()=>{
            this.$store.commit('alertSuccess', 'Успешно создано')
            this.directionCatsLoad();
          })
          .catch((e)=> {
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку')
          });
    },
    directionCatUpdateAction(v){
      axios.put(Api.host+'/directionCat/'+v.id+'/', v)
          .then(()=>{
            this.$store.commit('alertSuccess', 'Успешно изменено')
            this.directionCatsLoad();
          })
          .catch((e)=> {
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку')
          });
    },
    directionCatDeleteAction(v){
      axios.delete(Api.host+'/directionCat/'+v.id+'/')
          .then(()=>{
            this.$store.commit('alertSuccess', 'Успешно удалено')
            this.directionCatsLoad();
          })
          .catch((e)=> {
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку')
          });
    },

    directionCreateAction(directionCat, v){
      v.directionCat = {
        id:directionCat.id
      }
      axios.post(Api.host+'/direction/', v)
          .then(()=>{
            this.$store.commit('alertSuccess', 'Успешно создано')
            this.directionCatsLoad();
          })
          .catch((e)=> {
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку')
          });
    },
    directionUpdateAction(v){
      axios.put(Api.host+'/direction/'+v.id+'/', v)
          .then(()=>{
            this.$store.commit('alertSuccess', 'Успешно изменено')
            this.directionCatsLoad();
          })
          .catch((e)=> {
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку')
          });
    },
    directionDeleteAction(v){
      axios.delete(Api.host+'/direction/'+v.id+'/')
          .then(()=>{
            this.$store.commit('alertSuccess', 'Успешно удалено')
            this.directionCatsLoad();
          })
          .catch((e)=> {
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку')
          });
    },

    serviceAttachAction(direction, items){
      let arr = [];
      items.forEach(v=>{
        arr.push(v.id)
      });
      axios.put(Api.host+'/direction/'+direction.id+'/attach/service/', arr)
          .then(()=>{
            this.$refs.services.load(true);
          })
          .catch((e)=> {
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку')
          });
    },
    serviceDettachAction(direction){
      let arr = [];
      this.servicesSelects.forEach(v=>{
        arr.push(v.id)
      });
      axios.put(Api.host+'/direction/'+direction.id+'/dettach/service/', arr)
          .then(()=>{
            this.servicesSelects = [];
            this.$refs.services.load(true);
          })
          .catch((e)=> {
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку')
          });
    }
  }
}
</script>

<style lang="scss">
.tree{
  .v-treeview-node__root, .v-treeview-node{
    margin-top:0 !important;
    margin-bottom:4px !important;
    min-height: 30px !important;
  }
}
</style>