<template>
  <v-form ref="form">
    <dialog-view
      :open="dataState.isModalOpen"
      :title="(dataState.modalData===null ? '' : typeof dataState.modalData.id=='undefined' ? 'Создать категорию' : 'Изменить категорию #'+dataState.modalData.id)"
      @cancel="dataState.modalClose()"
    >
      <template v-slot:barActions v-if="dataState.modalData!==null">
        <log entity-name="ServiceCat" :entity-id="dataState.modalData.id" small/>
      </template>
      <template v-slot:content v-if="dataState.modalData!==null">
        <v-text-field label="Название" v-model="dataState.modalData.name" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
        <v-text-field label="Сортировка" v-model="dataState.modalData.sort" :rules="formFieldRules.required" class="mt-4" outlined dense hide-details="auto"/>
        <v-text-field label="Внешний код" v-model="dataState.modalData.isid" type="text" class="mt-4 mb-4" outlined dense hide-details="auto"></v-text-field>
        <com-branch-autocomplete-single :select.sync="dataState.modalData.comBranch" />
        <!-- <autocomplete-multiple name="Направления" class="mb-0" :items.sync="directions" :select.sync="dataState.modalData.directions" :loading="apiDirections.loading"/> -->
        <direction-autocomplete-multiple :select.sync="dataState.modalData.directions" categoryValidation/>
        <v-checkbox label="Программа" v-model="dataState.modalData.isProgram" class="mt-2" dense hide-details="auto"/>
        <v-row v-if="dataState.modalData.isProgram">
          <v-col>
            <v-text-field label="Текущая цена" v-model="dataState.modalData.price" type="number" readonly class="mt-4" outlined dense hide-details="auto"/>
          </v-col>
          <v-col>
            <v-text-field label="Новая цена" v-model="dataState.modalData.priceNew" type="number" class="mt-4" outlined dense hide-details="auto">
              <template v-slot:append>
                <span class="pt-1">
                  {{getServiceCatUpdatePriceNewPercent()}}
                </span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-textarea label="Описание" v-model="dataState.modalData.des" rows="1" class="mt-2" auto-grow outlined dense hide-details="auto"/></v-col>
          <v-col sm="12" class="text-center"><btn-text-accept name="Вернуть исходную цену" text="Вернуть исходную цену?" color="error" @accept="restoreDefaultPrice(dataState.modalData.id)"/></v-col>
        </v-row>

        <v-checkbox label="Отображать на сервисах (сайт, моб. приложение)" v-model="dataState.modalData.isShowForClient" class="mt-2" dense hide-details="auto"/>
        <v-checkbox label="Отображать для начисления" v-model="dataState.modalData.isShowForOrder" class="mb-3" dense hide-details="auto"/>
      </template>
      <template v-slot:actions>
        <v-btn v-if="dataState.modalData!=null" text color="primary" @click="submit" :loading="dataState.isLoading">
          {{typeof dataState.modalData.id=='undefined' ? 'Создать' : 'Изменить'}}
        </v-btn>
      </template>
    </dialog-view>
  </v-form>
</template>

<script>
import {FormFieldRules} from "@/plugins/formFieldRules";
import DialogView from "@/components/DialogView";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import Log from "@/views/Log/Log";
import ComBranchAutocompleteSingle from "@/componentsV2/custom/ComBranchAutocompleteSingle.vue";
import BtnTextAccept from "@/componentsV2/base/BtnTextAccept.vue";
import DirectionAutocompleteMultiple from "@/componentsV2/custom/DirectionAutocompleteMultiple.vue";

export default {
  components: {DirectionAutocompleteMultiple, ComBranchAutocompleteSingle, Log, DialogView, BtnTextAccept},
  props:[
    'state'
  ],
  data: () => ({
    formFieldRules: FormFieldRules,
    dataState: new State(),
  }),
  watch:{
    state: {
      deep: true,
      handler() {
        if(this.state.isModalOpen){
          if(this.state.modalData===null) {
            this.dataState.modalOpen({
              name: null,
              sort: 0,
              price:0,
              isid:null,
              isProgram: false,
              isShowForClient: false,
              isShowForOrder: false,
              des: null,
              comBranch: null,
              directions:[],
            });
          } else if(typeof this.state.modalData.serviceCatId!=='undefined'){
            this.dataState.modalOpen({
              serviceCatId:this.state.modalData.serviceCatId,
              name:null,
              sort:0,
              price:0,
              isid:null,
              isProgram:false,
              isShowForClient:false,
              isShowForOrder: false,
              des:null,
              comBranch: null,
              directions:[],
            });
          } else if(typeof this.state.modalData.id!=='undefined') {
            this.dataState.modalOpen({
              id:this.state.modalData.id,
              name:this.state.modalData.name,
              sort:this.state.modalData.sort,
              price:this.state.modalData.price,
              isid:this.state.modalData.isid,
              isProgram:this.state.modalData.isProgram,
              isShowForClient:this.state.modalData.isShowForClient,
              isShowForOrder: this.state.modalData.isShowForOrder,
              des:this.state.modalData.des,
              comBranch: this.state.modalData.comBranch,
              directions:this.state.modalData.directions,
            });
          }
        }
      },
    },
    dataState: {
      deep: true,
      handler() {
        this.state.isModalOpen = this.dataState.isModalOpen;
      }
    }
  },
  methods:{
    getServiceCatUpdatePriceNewPercent(){
      if (this.dataState.modalData.priceNew!==null){
        if (!isNaN(this.dataState.modalData.priceNew) && !isNaN(this.dataState.modalData.price)){
          if(this.dataState.modalData.price>0){
            let v =  this.dataState.modalData.priceNew/(this.dataState.modalData.price/100);
            v = 100-v;
            v *= -1;
            return v.toFixed(2)+'%';
          }
        }
      }
      return '';
    },

    submit(){
      if (this.$refs.form.validate()){
        this.dataState.stateLoading();

        let modalData = JSON.parse(JSON.stringify(this.dataState.modalData));
        modalData.price = this.dataState.modalData.priceNew;

        if(typeof this.dataState.modalData.id=='undefined'){
          axios.post(Api.host+'/serviceCat/', this.dataState.modalData)
              .then(r =>{
                this.$refs.form.resetValidation();
                this.$store.commit('alertSuccess', 'Категория создана');
                this.dataState.stateSuccess();
                this.dataState.modalClose();
                this.$emit('success', r.data.id)
              })
              .catch(e =>{
                Api.responseError(e)
                this.dataState.stateError();
              });
        } else {
          axios.put(Api.host+'/serviceCat/'+modalData.id+'/', modalData)
              .then(r =>{
                this.$refs.form.resetValidation();
                this.$store.commit('alertSuccess', 'Категория создана');
                this.dataState.stateSuccess();
                this.dataState.modalClose();
                this.$emit('success', r.data.id)
              })
              .catch(e =>{
                Api.responseError(e)
                this.dataState.stateError();
                this.$store.commit('alertError', e.response.data.message);
              });
        }
      }
    },
    restoreDefaultPrice(serviceCat) {
      let arr = [];
      arr.push(serviceCat);
      Api.service.service.restoreDefaultPrice(arr, null, ()=>{
        this.$store.commit('alertSuccess', 'Цены успешно изменены');
        this.dataState.modalClose();
        this.$emit('success');
      }, (e)=>{
        this.dataState.modalClose();
        this.$store.commit('alertError', e.response.data.message);
      });
    }
  }
}
</script>