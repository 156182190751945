<template>
  <autocomplete-single :name="name" :search-text.sync="searchText" :items="items" :select.sync="localSelect" :loading="loading" backgroundColor="white">
    <template v-slot:itemInfo="{item}">
      <v-row no-gutters>
        <v-col cols="auto">
          {{item.name}}
        </v-col>
        <v-col v-if="!$tools.isEmpty(item.info.med) && !$tools.isEmpty(item.info.executor) && !$tools.isEmpty(item.info.client)">
          <tooltip right>
            <template v-slot:default>
              <v-btn small icon class="ml-2 mt-n1">
                <v-icon small>mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:content>
              <label-view v-if="!$tools.isEmpty(item.info.med)" title="Медицинская информация">
                {{item.info.med}}
              </label-view>
              <label-view v-if="!$tools.isEmpty(item.info.executor)" title="Для исполнителя">
                {{item.info.executor}}
              </label-view>
              <label-view v-if="!$tools.isEmpty(item.info.client)" title="Для клиента">
                {{item.info.client}}
              </label-view>
            </template>
          </tooltip>
        </v-col>
      </v-row>
    </template>
  </autocomplete-single>
</template>

<script>
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import axios from "axios";
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import LabelView from "@/components/LabelView.vue";
export default {
  components: {LabelView, Tooltip, AutocompleteSingle},
  props:[
    'name',
    'select',
    'labId'
  ],
  data:()=>({
    localSelect: null,
    searchText: null,
    loading:false,
    items:[],
  }),
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    },
    searchText(){
      if(!this.$tools.isEmpty(this.searchText)) {
        this.loading = true;
        axios.get('/napi/lab/'+this.labId+'/item/search/?q='+encodeURI(this.searchText))
            .then(r =>{
              this.items = r.data;
              this.items.forEach(v=>{
                v._name = v.code
              });
              this.loading = false;
            })
            .catch(() =>{
              this.loading = false;
            });
      }  
    }
  },
  mounted() {
    this.localSelect = this.select;
  }
}
</script>