<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" :disabled="disabled" :loading="loading" depressed :icon="typeof btnIcon !=='undefined'" :x-small="typeof xSmall !=='undefined'" :color="color" :class="btnClass" :dark="typeof dark!=='undefined'" :block="typeof block!=='undefined'">
        <v-icon v-if="typeof icon!=='undefined'" small :class="typeof btnIcon === 'undefined' ? 'mr-2' :''">{{icon}}</v-icon>{{name}}
      </v-btn>
    </template>
    <v-sheet class="ma-4" width="240" style="margin-left:-100px">
      <v-row no-gutters>
        <v-col v-if="!$tools.isEmpty(text)" cols="12" class="body-2 mb-4" v-html="text">
        </v-col>
        <v-col>
          <v-btn text small>Отменить</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="$emit('accept')" text small color="primary">Подтвердить</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-menu>
</template>

<script>

export default {
  props:[
    'btnClass',
    'name',
    'color',
    'text',
    'dark',
    'block',
    'disabled',
    'icon',
    'loading',
    'btnIcon',
    'xSmall'
  ],
  data:()=>({
  })
}
</script>