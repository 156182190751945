<template>
  <div>
    <portal to="tabs">
      <v-tabs v-model="tabIndex" background-color="transparent">
        <v-tab>Услуги</v-tab>
        <v-tab>Номенклатура</v-tab>
        <v-tab>Направления</v-tab>
        <v-tab>История</v-tab>
      </v-tabs>
    </portal>
    <Services v-if="tabIndex===0"/>
    <NomenCats v-if="tabIndex===1"/>
    <DirectionCats v-if="tabIndex===2"/>
    <PriceHistory v-if="tabIndex===3"/>
  </div>

</template>

<script>
import Services from "@/views/Service/Services.vue";
import NomenCats from "@/views/Price/NomenCats.vue";
import DirectionCats from "@/views/Price/DirectionCats.vue";
import PriceHistory from "@/views/Price/PriceHistory.vue";

export default {
  components: {DirectionCats, NomenCats, Services, PriceHistory},
  data: () => ({
    tabIndex:0,
  })
}
</script>