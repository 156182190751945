<template>
  <autocomplete-single name="Специальность" :items="arr" :select.sync="localSelect" :loading="api.loading">
    <template v-slot:itemIcon="{item}">
      <v-avatar :color="item.color" size="10" class="mr-1" style="margin-top:-3px"/>
    </template>
    <template v-slot:selectedInfo="{item}">
      <v-avatar :color="item.color" size="10" class="mr-1" style="margin-top:-2px"/> {{item.name}}
    </template>
  </autocomplete-single>
</template>

<script>
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import GraphqlApi from "@/plugins/graphqlApi";

export default {
  components: {AutocompleteSingle},
  props:[
    'select',
  ],
  data:()=>({
    api: new GraphqlApi(),
    arr: [],
    localSelect: null
  }),
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    }
  },
  mounted() {
    this.localSelect = this.select;
    this.api.requestSingleModelMultipleDataV1(this.arr, 'Spec', '/api/graphql/', `
      query Query{
        Spec(sortBy:"name",sortDesc:false){
          id
          name
          color
        }
      }
    `, null, null, (v)=>{
      v.id = parseInt(v.id);
      return v;
    }, true);
  }
}
</script>